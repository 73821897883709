import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import Grid from '@mui/material/Grid';
import AdminNavBar from '../navigation/AdminNavBar';
import { Typography, Breadcrumbs, FormControlLabel, FormGroup } from '@mui/material';
import isEmpty from '../../wumdrophubsreactshared/_utils/isEmpty';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircle';
import { Link } from 'react-router-dom';
import useStyles from './AdminContainer.css';
import NavBar from '../navigation/NavBar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import UploadIcon from '@mui/icons-material/Publish';
import { RATES, STORES } from 'wumdrophubsreactshared/_constants/typeConstants';
import withStylesHook from './../../utils/withStylesHook';
import {NationalSwitch} from './../common/NationalSwitch';
export class AdminContainer extends Component {

    convertToCamelCase = (type) => {
        if (isEmpty(type))
            return "";
        return String(type).split('-').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
    }

    render() {
        const {
            classes,
            type,
            linkText,
            createLink,
            isReadOnly,
            uploadLink,
            currentLinkOverride,
            currentLinkDisabled,
            buttonFormHandler,
            currentBaseType,
            firstParentId,
            firstParentText,
            firstParentType,
            firstParentLinkText,
            secondParentId,
            secondParentText,
            secondParentType,
            thirdParentId,
            thirdParentText,
            thirdParentType,
            notificationList,
            scrollable,
            isNational,
            onNationalClick
        } = this.props;

        let currentLink = type;
        if (!isEmpty(firstParentType) && !isEmpty(firstParentId) && !isEmpty(currentBaseType)) {
            currentLink = `${firstParentType}/${firstParentId}/${currentBaseType}`;
        }
        if (!isEmpty(firstParentType) && !isEmpty(firstParentId) && !isEmpty(secondParentType) && !isEmpty(secondParentId)) {
            currentLink = `${firstParentType}/${firstParentId}/${secondParentType}/${secondParentId}`;
        }
        if (!isEmpty(firstParentType) && !isEmpty(firstParentId) && !isEmpty(secondParentType) && !isEmpty(secondParentId) && !isEmpty(currentBaseType)) {
            currentLink = `${firstParentType}/${firstParentId}/${secondParentType}/${secondParentId}/${currentBaseType}`;
        }
        if (!isEmpty(firstParentType) && !isEmpty(firstParentId) && !isEmpty(secondParentType) && !isEmpty(secondParentId) && !isEmpty(thirdParentType) && !isEmpty(thirdParentId)) {
            currentLink = `${firstParentType}/${firstParentId}/${secondParentType}/${secondParentId}/${thirdParentType}/${thirdParentId}`;
        }

        if (!isEmpty(currentLinkOverride)) {
            currentLink = currentLinkOverride;
        }

        return (
            <div className={classes.root}>
                <NavBar fullWidth={true} />
                <div className={classes.appFrame}>
                    <AdminNavBar>
                        <div elevation={0} className={classes.links}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" htmlColor="white" />} aria-label="breadcrumb">
                                {!isEmpty(firstParentType) &&
                                    <Link className={classes.link} to={`/${firstParentType}`}>{!isEmpty(firstParentLinkText) ? this.convertToCamelCase(firstParentLinkText) : this.convertToCamelCase(firstParentType)}</Link>
                                }
                                {!isEmpty(firstParentText) &&
                                    <Typography className={classes.crumbText}>{firstParentText}</Typography>
                                }
                                {!isEmpty(firstParentType) && !isEmpty(secondParentType) &&
                                    <Link className={classes.link} to={`/${firstParentType}/${firstParentId}/${secondParentType}`}>{this.convertToCamelCase(secondParentType)}</Link>
                                }
                                {!isEmpty(firstParentText) && !isEmpty(secondParentText) &&
                                    <Typography className={classes.crumbText}>{secondParentText}</Typography>
                                }
                                {!isEmpty(firstParentType) && !isEmpty(secondParentType) && !isEmpty(thirdParentType) &&
                                    <Link className={classes.link} to={`/${firstParentType}/${firstParentId}/${secondParentType}/${secondParentId}/${thirdParentType}`}>{this.convertToCamelCase(thirdParentType)}</Link>
                                }
                                {!isEmpty(firstParentText) && !isEmpty(secondParentText) && !isEmpty(thirdParentText) &&
                                    <Typography className={classes.crumbText}>{thirdParentText}</Typography>
                                }
                                {
                                    !isEmpty(currentLinkDisabled) && currentLinkDisabled === true ?
                                        <Typography className={classes.link}>{this.convertToCamelCase(linkText)}</Typography>
                                        :
                                        <Link 
                                        className={classes.link} 
                                        to={`/${currentLink}`}
                                        onClick={isNational ? onNationalClick : undefined}
                                        >
                                            {this.convertToCamelCase(linkText)}
                                        </Link>
                                }
                            </Breadcrumbs>
                        </div>

                        {!isEmpty(notificationList) && notificationList}

                        {isReadOnly === false ?
                            <IconButton className={classes.button} color="inherit" aria-label="Create" component={Link} to={isEmpty(createLink) ? `/${type}/add` : createLink} >
                                <AddIcon />
                            </IconButton> 
                        : null
                        }

                        {
                            
                             [STORES,RATES].includes(type)?
                             <IconButton color="inherit" aria-label="Upload" className={classes.secondaryIconButton} component={Link} to={isEmpty(uploadLink) ? `/${type}/batches/add` : uploadLink} >
                              <UploadIcon />
                             </IconButton>: null

                        }
                           
                        {!isEmpty(buttonFormHandler) ?
                            <IconButton className={classes.button} color="inherit" aria-label="Create" onClick={buttonFormHandler}>
                                <AddIcon />
                            </IconButton> : null
                        }

                        {isNational && (
                            <FormGroup>
                                <FormControlLabel
                                    className={classes.link}
                                    control={<NationalSwitch defaultChecked onClick={onNationalClick} />}
                                    label="National"
                                />
                            </FormGroup>
                        )}

                    </AdminNavBar>
                    <Grid item xs={12}>
                        <div className={scrollable ? classes.contentScroll : classes.content} >
                            {this.props.children}
                        </div>
                    </Grid>
                </div>
            </div>
        );
    }
}

AdminContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStylesHook(useStyles,{ withTheme: true }, { name: 'AdminContainer' }),
)(AdminContainer);