import {
    GET_TERRITORIES_AREAS,
    POST_TERRITORIES_AREAS,
    PUT_TERRITORIES_AREAS,
    SEARCH_TERRITORIES_AREAS,
    DELETE_TERRITORIES_AREAS,
    CLEAR_TERRITORIES_AREAS,
    CLEAR_TERRITORIES_AREAS_SUCCESS,
    GET_AREAS_BY_ADDRESS
} from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS, DELETE_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {},
    areasByAddressId:{},
    searchPolygon:{},
    success: ""
}

export default function(state = initialState, action ) {
    switch(action.type) {
        case GET_TERRITORIES_AREAS:
            return {
                ...state,
                data: action.payload,
                search: action.search
            }
        case GET_AREAS_BY_ADDRESS:      
            const existingAreas = Array.isArray(state.areasByAddressId) ? state.areasByAddressId : [];
            const newAreas = Array.isArray(action.payload) ? action.payload : [action.payload];
        
            const updatedAreas = existingAreas.map(existingItem => {
                const matchingNewItem = newAreas.find(newItem => newItem.id === existingItem.id);
                return matchingNewItem ? { ...existingItem, ...matchingNewItem } : existingItem;
            });
            newAreas.forEach(newItem => {
                if (!updatedAreas.some(existingItem => existingItem.id === newItem.id)) {
                    updatedAreas.push(newItem);
                }
            });
            return {
                ...state,
                areasByAddressId: updatedAreas
            };   
        case POST_TERRITORIES_AREAS:
            return {
                ...state,
                data: action.payload,
                success:`${POST_SUCCESS} area: ${action.payload.areaName}`
            }
        case PUT_TERRITORIES_AREAS:
            return {
                ...state,
                data: action.payload,
                success: `${PUT_SUCCESS} area: ${action.payload.areaName}`
            }
        case SEARCH_TERRITORIES_AREAS:
            return {
                ...state,
                searchPolygon: action.payload,
            }    
        case DELETE_TERRITORIES_AREAS:
            return {
                ...state,
                success: `${DELETE_SUCCESS} ${action.payload.length} territory areas.`
            }
        case CLEAR_TERRITORIES_AREAS:
            return {
                ...state,
                data: {},
                success: ''
            }
        case CLEAR_TERRITORIES_AREAS_SUCCESS:
            return {
                ...state,
                success: ''
            }
        default: 
            return state;
    }
}