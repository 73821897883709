import { makeStyles } from '@mui/styles';
import {SLATE_COLOR, TINY_FONT, TERTIARY_COLOR, SMALL_FONT} from '../../../wumdrophubsreactshared/_constants/styleConstants';

const useStyles = makeStyles((theme) => ({
  labelText: {
        fontSize: `${TINY_FONT}px !important`,
        fontWeight: "bold !important",
        paddingTop: `12px !important`,
        paddingLeft: '2px !important',
        paddingBottom: "5px !important",
        color: `${SLATE_COLOR} !important`
    },
    selectContainer: {
      marginBottom:'6px !important'
    },
    radioChecked: {
      color: `${TERTIARY_COLOR} !important`,
  },
  radioLabelSmall: {
      fontSize: `${SMALL_FONT}px !important`
  },
}));

export default useStyles;